import * as React from "react";
// @ts-ignore
import angImage from '../../../static/images/ang.svg';
// @ts-ignore
import javaImage from '../../../static/images/java.png';
// @ts-ignore
import sqlImage from '../../../static/images/ms.png';
// @ts-ignore
import ngnixImage from '../../../static/images/ngnx.png';
// @ts-ignore
import pgImage from '../../../static/images/pgr.png';
// @ts-ignore
import orImage from '../../../static/images/orcl.png';
// @ts-ignore
import htmlImage from '../../../static/images/htmlcss.svg';
// @ts-ignore
import vueImage from '../../../static/images/vue.png';
// @ts-ignore
import reactImage from '../../../static/images/rct.png';
// @ts-ignore
import tsImage from '../../../static/images/ts.png';
// @ts-ignore
import netImage from '../../../static/images/net2.png';
// @ts-ignore
import phImage from '../../../static/images/ph.png';


export default class TechSection extends React.Component {
    render() {
        return (
            <section id="technologies" className="technologies">
                <div className="container">
                    <h2 className="title">Технологии</h2>
                    <div className="tech-list">
                        <div className="row">
                            <div>
                                <span className="timg2"><img src={sqlImage}/></span>
                                <h3>MS SQL</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={orImage}/></span>
                                <h3>Oracle</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={pgImage}/></span>
                                <h3>PostgreSQL</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={netImage}/></span>
                                <h3>.NET</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={javaImage}/></span>
                                <h3>Java</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={angImage}/></span>
                                <h3>Angular</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                <span className="timg2"><img src={phImage}/></span>
                                <h3>Python</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={vueImage}/></span>
                                <h3>Vue</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={reactImage}/></span>
                                <h3>React</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={htmlImage}/></span>
                                <h3>HTML5 CSS3</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={ngnixImage}/></span>
                                <h3>Nginx</h3>
                            </div>
                            <div>
                                <span className="timg2"><img src={tsImage}/></span>
                                <h3>TypeScript</h3>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div>
                                <span>03</span>
                                <h3>Северный ветер дует в правое ухо</h3>
                                <p>Квинтэссенцию победы маркетинга над разумом и должны быть превращены в посмешище.</p>
                            </div>
                            <div>
                                <span>04</span>
                                <h3>Парацетамол помогает не каждому</h3>
                                <p>Не следует, однако, забывать, что убеждённость некоторых оппонентов, а также свежий взгляд на привычные вещи</p>
                            </div>
                            <div>
                                <span>05</span>
                                <h3>Ланфренланфралантатита</h3>
                                <p>Квинтэссенцию победы маркетинга над разумом и должны быть превращены в посмешище.</p>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </section>
        )
    }
}
